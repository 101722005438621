import React, { useState } from 'react';
import Registerpage from './components/Registerpage.js';
import Homecontent from "./components/Homecontent.js";
import Courses from "./components/Courses.js";
import Header from './components/Header';
import Footer from "./components/Footer"; 
import Popuppage from "./components/Popuppage.js";
import Contact from "./components/Contact.js";
import Translation from "./components/Translation.js";
import AboutUs from "./components/AboutUs.js";
import Faqs from "./components/Faqs.js";
import NotFound from "./components/NotFound.js"; // Import the NotFound component
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import './components/css/app.css';

function App() {
  const [showPopup, setPopup] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const location = useLocation();

  function togglePopup(data = null) {
    setCourseData(data);
    setPopup(!showPopup);
  }

  // Define the valid paths for which Header and Footer should be displayed
  const validPaths = ["/", "/register", "/courses", "/contact", "/translation", "/aboutus", "/faqs"];
  
  // Check if the current path is valid
  const isValidPath = validPaths.includes(location.pathname);

  return (
    <div className="app-container">
      {/* Conditionally render Header and Footer based on valid paths */}
      {isValidPath && <Header />}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Homecontent />} />
          <Route path="/register" element={<Registerpage />} />
          <Route path="/courses" element={<Courses togglePopup={togglePopup} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/translation" element={<Translation />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/faqs" element={<Faqs />} />
          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      {isValidPath && <Footer />}
      {showPopup && <Popuppage togglePopup={togglePopup} data={courseData} />}
    </div>
  );
}

// Wrap your App with Router
const WrappedApp = () => (
  <Router>
    <App />
  </Router>
);

export default WrappedApp;
