import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Bootstrap JS bundle (includes Popper.js)

import ReactDOM from 'react-dom/client';
import App from './App';

const root=ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
     <App />
  </div>
   
);

