import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import logo from "./Ourgermanlogo.png";
import { NavLink } from "react-router-dom";
import "./css/header.css";

export default function Header() {
  const handleNavLinkClick = () => {
    // Collapse the navbar after clicking a link
    const navbarCollapse = document.getElementById("navbarNav");
    if (navbarCollapse) {
      navbarCollapse.classList.remove("show");
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="Logo" width="190" height="80" className="d-inline-block align-text-top" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/" activeClassName="active" onClick={handleNavLinkClick}>Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/aboutus" activeClassName="active" onClick={handleNavLinkClick}>About Us</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/courses" activeClassName="active" onClick={handleNavLinkClick}>Courses</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to='/register' activeClassName="active" onClick={handleNavLinkClick}>Register for Course</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/translation" activeClassName="active" onClick={handleNavLinkClick}>Certified Translations</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" activeClassName="active" onClick={handleNavLinkClick}>Contact us</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
