import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Helmet} from 'react-helmet'
import {
  faClock,
  faDollarSign,
  faUser,
  faStar,
  faPhone,
  faFileUpload,
  faQuoteRight,
  faCheckCircle,
  faEnvelope,
  faShippingFast
} from '@fortawesome/free-solid-svg-icons';
import "./css/translation.css";

export default function Translation() {
  return (
    <div className="translationContainer">
      <Helmet>
        <title>Certified Translation Services | Best in India</title>
        <meta name="description" content="Get certified translation services from the best translation school in India. We ensure accuracy, professionalism, and quick turnaround for all your translation needs." />
        <meta name="keywords" content="certified translation, translation services, professional translation, best translation services in India, accurate translation, document translation, language translation, certified translators" />
        <link rel="canonical" href="https://www.ourgermanschool.de/translation" />
      </Helmet>
      {/* Introduction Section */}
      <div className="introSection">
        <h1 className="introTitle">Certified English to German Translations</h1>
        <p className="introText">
          Need a certified translation from English to German? We provide accurate and legally binding translations for all types of documents. Our certified translators adhere to strict quality standards and legal requirements.
        </p>
        <div className="infoSections">
          <div className="infoSection">
            <h3 className="offerTitle">What We Offer</h3>
            <ul className="offerList">
              <li><FontAwesomeIcon icon={faClock} className="offerIcon" /> Fast turnaround times</li>
              <li><FontAwesomeIcon icon={faDollarSign} className="offerIcon" /> Competitive pricing</li>
              <li><FontAwesomeIcon icon={faUser} className="offerIcon" /> Expert translators</li>
              <li><FontAwesomeIcon icon={faStar} className="offerIcon" /> 100% satisfaction guaranteed</li>
            </ul>
          </div>
          <div className="infoSection">
            <h3 className="commonDocsTitle">Commonly Translated Documents</h3>
            <ul className="commonDocsList">
              <li>Legal documents</li>
              <li>Birth certificates</li>
              <li>Marriage certificates</li>
              <li>Diplomas</li>
              <li>Transcripts</li>
              <li>Medical records</li>
            </ul>
          </div>
        </div>
        <p className="contactText">Contact us for a free quote or to learn more about our certified translation services.</p>
      </div>

      {/* Steps Section */}
      <div className="translationSteps">
        <h2 className="stepsTitle">How to Order Your Certified Translation</h2>
        <div className="stepsList">
          <div className="step">
            <FontAwesomeIcon icon={faPhone} size="2x" className="stepIcon" />
            <div className="stepContent">
              <h4>Step 1: Initial Contact</h4>
              <p>Get in touch with us to discuss your translation needs. Provide details about the document(s) you require translated, including the document type and the desired turnaround time.</p>
            </div>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faFileUpload} size="2x" className="stepIcon" />
            <div className="stepContent">
              <h4>Step 2: Document Submission</h4>
              <p>Send us high-quality scanned copies of your original documents for accurate translation.</p>
            </div>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faQuoteRight} size="2x" className="stepIcon" />
            <div className="stepContent">
              <h4>Step 3: Receive a Quote</h4>
              <p>We will provide a detailed quote outlining the translation costs and any additional fees, such as courier charges for document delivery to India.</p>
            </div>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faCheckCircle} size="2x" className="stepIcon" />
            <div className="stepContent">
              <h4>Step 4: Order Confirmation</h4>
              <p>Once you approve the quote, confirm your order by making the necessary payment.</p>
            </div>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faEnvelope} size="2x" className="stepIcon" />
            <div className="stepContent">
              <h4>Step 5: Digital Delivery</h4>
              <p>Upon completion, you will receive a digital copy of the translated documents via email.</p>
            </div>
          </div>
          <div className="step">
            <FontAwesomeIcon icon={faShippingFast} size="2x" className="stepIcon" />
            <div className="stepContent">
              <h4>Step 6: Physical Delivery</h4>
              <p>The certified, hard copy translations bearing the official seal of our Germany-authorized translator will be shipped to your specified address in India.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
