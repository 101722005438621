import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./css/courses.css";
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';

export default function Courses({ togglePopup }) {
  const courseData = [
    {
      level: 'A1',
      title: 'Beginner',
      description: 'Can understand and use familiar everyday expressions and very basic phrases aimed at the satisfaction of needs of a concrete type. Can introduce him/herself and others and can ask and answer questions about personal details such as where he/she lives, people he/she knows and things he/she has. Can interact in a simple way provided the other person talks slowly and clearly and is prepared to help.'
    },
    {
      level: 'A2',
      title: 'Pre-Intermediate',
      description: 'Can understand sentences and frequently used expressions related to areas of most immediate relevance (e.g., very basic personal and family information, shopping, local geography, employment). Can communicate in simple and routine tasks requiring a simple and direct exchange of information on familiar and routine matters. Can describe in simple terms aspects of his/her background, immediate environment and matters in areas of immediate need.'
    },
    {
      level: 'B1',
      title: 'Intermediate',
      description: 'Can understand the main points of clear standard input on familiar matters regularly encountered in work, school, leisure, etc. Can deal with most situations likely to arise whilst travelling in an area where the language is spoken. Can produce simple connected text on topics which are familiar or of personal interest. Can describe experiences and events, dreams, hopes & ambitions and briefly give reasons and explanations for opinions and plans.'
    },
    {
      level: 'B2',
      title: 'Upper-Intermediate',
      description: 'Can understand the main ideas of complex text on both concrete and abstract topics, including technical discussions in his/her field of specialisation. Can interact with a degree of fluency and spontaneity that makes regular interaction with native speakers quite possible without strain for either party. Can produce clear, detailed text on a wide range of subjects and explain a viewpoint on a topical issue giving the advantages and disadvantages of various options.'
    },
    {
      level: 'C1',
      title: 'Advanced',
      description: 'Can understand a wide range of demanding, longer texts, and recognise implicit meaning. Can express him/herself fluently and spontaneously without much obvious searching for expressions. Can use language flexibly and effectively for social, academic and professional purposes. Can produce clear, well-structured, detailed text on complex subjects, showing controlled use of organisational patterns, connectors and cohesive devices.'
    },
    {
      level: 'C2',
      title: 'Mastery',
      description: 'Can understand with ease virtually everything heard or read. Can summarise information from different spoken and written sources, reconstructing arguments and accounts in a coherent presentation. Can express him/herself spontaneously, very fluently and precisely, differentiating finer shades of meaning even in more complex situations.'
    }
  ];

  return (
    <div className="background-wrapper">
      <Helmet>
        <title>German Language Courses | Best in India</title>
        <meta name="description" content="Explore our comprehensive German language courses for all levels at the No. 1 German language school in India. Enroll now for flexible timing and personalized learning!"/>
        <meta name="keywords" content="German courses, learn German online, intensive German classes, weekend German courses, weekday German classes, best German language courses in India"/>
        <link rel="canonical" href="https://www.ourgermanschool.de/courses" />
      </Helmet>
      <div className="courseContainer">
        <div className="row justify-content-center">
          {courseData.map(data => (
            <div className="col-md-6 mb-4 cardBox" key={data.level}>
              <NavLink className="card-link custom-link" onClick={() => togglePopup(data)}>
                <div className="card custom-card h-100 d-flex align-items-center">
                  <div className="row g-0">
                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                      <div className="icon-container">
                        <h2 className="rounded-pill bg-black text-white text-center p-3 m-0 h2-custom">{data.level}</h2>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="card-body">
                        <h5 className="card-title">{data.title}</h5>
                        <p className="card-text">{data.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
