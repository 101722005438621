import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import "./css/footer.css";

export default function Footer() {
  return (
    <footer className="py-5">
      <div className="container">
        <div className="row">
          {/* First Column */}
          <div className="col-md-6">
            <h5>Quick Links</h5>
            <div className="quick-links">
              <div className="column">
                <NavLink to="/" className="footer-link">Home</NavLink>
                <NavLink to="/faqs" className="footer-link">FAQs</NavLink>
                <NavLink to="https://www.goethe.de/ins/gb/en/spr/unt/kum/mdg/ver.html" className="footer-link">CEFR</NavLink>
              </div>
              <div className="column">
                <NavLink to="https://www.daad.de/en/studying-in-germany/scholarships/daad-scholarships/" className="footer-link">Daad Scholarships</NavLink>
                <NavLink to="https://www.goethe.de/ins/in/en/spr/prf.html" className="footer-link">Goethe Exams</NavLink>
                <NavLink to="/courses" className="footer-link">Courses</NavLink>
              </div>
            </div>
          </div>

          {/* Second Column */}
          <div className="col-md-6">
            <h5>Connect with Us</h5>
            <div className="footer-icons">
              <a onClick={() => window.open('https://wa.me/917356797959')}>
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
              <a href="https://www.instagram.com/ourgermanschool/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="tel:+917356797959" aria-label="Phone">
                <FontAwesomeIcon icon={faPhone} />
              </a>
              <a onClick={() => window.location.href = 'mailto:info@ourgermanschool.de'}>
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </div>
            <div className="location-info">
              <div><FontAwesomeIcon icon={faMapMarkerAlt} /> Bangalore, Karnataka</div>
              <div><FontAwesomeIcon icon={faMapMarkerAlt} /> Calicut, Kerala</div>
            </div>
          </div>
        </div>
      </div>
      {/* Centered Copyright Text */}
      <div className="text-center mt-4">
        <p className="copyright">&copy; {new Date().getFullYear()} Our German School. All rights reserved.</p>
      </div>
    </footer>
  );
}
