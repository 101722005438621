import React from "react";
import "./css/aboutus.css"; // Ensure you create and import the CSS file
import {Helmet} from 'react-helmet';

export default function AboutUs() {
  return (
    <div className="aboutContainer">
      <Helmet>
        <title>About Us | Leading German Language School in India</title>
        <title>About Us | Leading German Language School in India</title>
        <meta name="description" content="Discover the best German language school in India. Learn about our experienced instructors and effective teaching methods. Your path to mastering German starts here!"/>
        <meta name="keywords" content="German language school, best German teachers in India, language learning, German language programs, about our school, German language institute"/>
        <link rel="canonical" href="https://www.ourgermanschool.de/aboutus" />

      </Helmet>
      <header className="aboutHeader">
        <h1>Discover the Joy of Learning German</h1>
        <p>At OurGermanSchool, we are passionate about making German accessible and enjoyable for Indian learners. Our mission is to equip you with the language skills to succeed in your personal and professional life.</p>
      </header>
      
      <section className="aboutSection">
        <h2>Our Team</h2>
        <p>Our dedicated team of experienced German teachers, many of whom have been working as medical doctors in Germany, brings extensive knowledge and passion to the classroom. By focusing on practical language skills, we foster a supportive learning environment that empowers you to confidently build your German proficiency.</p>
      </section>

      <section className="aboutSection">
        <h2>Our Teaching Methods</h2>
        <p>We believe in a holistic approach to language learning. Our interactive and engaging methods combine grammar, vocabulary, and conversation practice to ensure you become a fluent German speaker. Whether you're a beginner or looking to enhance your existing skills, we have a course tailored to your needs.</p>
      </section>

      <section className="aboutSection">
        <h2>Our Courses</h2>
        <p>From basic conversational German to advanced language proficiency, we offer a range of courses to suit different learning styles and goals. Our curriculum is designed to help you achieve fluency while developing a deep appreciation for German culture.</p>
      </section>

      <section className="aboutSection">
        <h2>Our Commitment to You</h2>
        <p>We are committed to providing exceptional language training. Our small class sizes and personalized attention ensure that you receive the support you need to succeed. We believe in creating a vibrant learning community where you can connect with fellow German enthusiasts.</p>
      </section>

      <footer id="aboutFooter">
        <h3>Join us on your German language journey!</h3>
      </footer>
    </div>
  );
}
