import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {Helmet} from 'react-helmet'
import "./css/contact.css";

export default function Contact() {
  const [showThankYou, setShowThankYou] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Gather form data from the target event
    const formData = {
      name: event.target.name.value,
      email: event.target.email.value,
      mobile: event.target.mobile.value,
      message: event.target.message.value,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setShowThankYou(true);
        event.target.reset(); // Reset the form fields after successful submission
      } else {
        console.error('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="contactContainer">
      <Helmet>
        <title>Contact Us | No. 1 German Language School in India</title>
        <meta name="description" content="Get in touch with the best German language school in India. We're here to answer your questions and help you start learning German. Contact us today!"/>
        <meta name="keywords" content="contact German language school, best language school in India, German language queries, German courses inquiries, get in touch"/>
        <link rel="canonical" href="https://www.ourgermanschool.de/contact" />
      </Helmet>
      <div className="contactIcons">
        <div className="icon-box" onClick={() => window.location.href = 'mailto:info@ourgermanschool.de'}>
          <FontAwesomeIcon icon={faEnvelope} className="icon" />
          <span>info@ourgermanschool.de</span>
        </div>
        <div className="icon-box" onClick={() => window.open('https://wa.me/917356797959')}>
          <FontAwesomeIcon icon={faWhatsapp} className="icon" />
          <span>+91-7356797959</span>
        </div>
        <div className="icon-box" onClick={() => window.open('https://wa.me/4917687884251')}>
          <FontAwesomeIcon icon={faPhone} className="icon" />
          <span>+49-17687884251</span>
        </div>
      </div>

      {!showThankYou ? (
        <form onSubmit={handleSubmit} className="contactDetails">
          <div className="row">
            <div className="col-md-4 mb-3">
              <input type="text" className="form-control" name="name" placeholder="Name" required />
            </div>
            <div className="col-md-4 mb-3">
              <input type="email" className="form-control" name="email" placeholder="Email" required />
            </div>
            <div className="col-md-4 mb-3">
              <input type="text" className="form-control" name="mobile" placeholder="Mobile Number" required />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <textarea className="form-control" name="message" placeholder="Message" rows="5" required></textarea>
            </div>
          </div>
          <div className="submit-button">
            <button type="submit">Submit</button>
          </div>
        </form>
      ) : (
        <div className="thank-you-message">
          <h3>Thank You!</h3>
          <p>Your message has been sent successfully. We will get back to you shortly.</p>
        </div>
      )}
    </div>
  );
}
