import React, { useState } from 'react';
import './css/faqs.css'; // Ensure this path matches your directory structure
import { Helmet } from 'react-helmet';

const faqs = [
  {
    question: 'What courses do you offer?',
    answer: 'We offer a range of German language courses, including beginner (A1), elementary (A2), intermediate (B1), upper-intermediate (B2), and advanced (C1 and C2) levels. We also provide specialized courses such as Business German, Exam Preparation, and Conversational German.',
  },
  {
    question: 'How can I determine my German language level?',
    answer: 'You can determine your German language level by taking a placement test, which we offer for free. The test assesses your proficiency and helps us place you in the appropriate course level. Alternatively, you can consult with our language advisors for guidance.',
  },
  {
    question: 'What is the duration of each course?',
    answer: 'The duration of each course varies depending on the level and intensity. Typically, a standard course lasts for 10 to 12 weeks with classes held twice a week. We also offer intensive courses that run daily for 4 to 6 weeks.',
  },
  {
    question: 'Do you offer online classes?',
    answer: 'Yes, all our courses are online. Our online courses are designed to provide the same quality of instruction as our in-person classes, with interactive lessons and resources accessible from anywhere.',
  },
  {
    question: 'Are your courses recognized for certification?',
    answer: 'Yes, we offer courses that prepare students for internationally recognized German language certifications such as the Goethe-Institut exams. Our institute is also an accredited test center for some of these exams.',
  },
  {
    question: 'What is the cost of the courses?',
    answer: 'The cost of our courses varies based on the level, duration, and format (online or in-person). We offer a range of options to fit different budgets, and discounts may be available for early registration or group bookings. Please contact us for detailed pricing information.',
  },
  {
    question: 'Do you provide materials for the course?',
    answer: 'Yes, we provide all necessary materials for the course, including textbooks, workbooks, and online resources. Additional resources and recommended materials may be suggested depending on the course level and requirements.',
  },
  {
    question: 'Can I get a refund if I need to withdraw from a course?',
    answer: 'We have a refund policy in place that allows for a partial refund if you need to withdraw from a course. The specific terms and conditions depend on the timing of your withdrawal and the course type. Please refer to our refund policy or contact us for more details.',
  },
  {
    question: 'How do I enroll in a course?',
    answer: 'To enroll in a course, you can visit our website and complete the online registration form, or you can contact our admissions office directly. We will guide you through the enrollment process and help you choose the right course based on your needs.',
  },
  {
    question: 'What are your qualifications and experience of instructors?',
    answer: 'Our instructors are highly qualified with degrees in German language teaching and extensive experience in language instruction. Many of them are Medical Doctors in Germany and have additional certifications in language teaching. We ensure that all our instructors are skilled in providing effective and engaging lessons.',
  },
];

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <Helmet>
        <title>Frequently Asked Questions - Learn German at OurGermanSchool</title>
        <meta name="description" content="Find answers to the most common questions about our German language courses. Learn about course details, certifications, enrollment, and more!" />
        <meta name="keywords" content="German language courses, learn German, FAQ, language learning, German exams, course enrollment, certifications" />
        <link rel="canonical" href="https://www.ourgermanschool.de/faqs" />
      </Helmet>

      <h1 className="faq-heading">Frequently Asked Questions</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div
            className={`faq-question ${activeIndex === index ? 'active' : ''}`}
            onClick={() => handleToggle(index)}
            aria-expanded={activeIndex === index ? 'true' : 'false'}
            aria-controls={`faq-answer-${index}`}
            role="button"
          >
            {faq.question}
            <span className="arrow" aria-hidden="true">
              {activeIndex === index ? '▲' : '▼'}
            </span>
          </div>
          <div
            id={`faq-answer-${index}`}
            className={`faq-answer ${activeIndex === index ? 'show' : ''}`}
            aria-hidden={activeIndex !== index}
          >
            {faq.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQPage;
